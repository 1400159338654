<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m7.857 7.636c.775-3.011 3.509-5.236 6.762-5.236 3.856 0 6.982 3.126 6.982 6.982 0 3.074-1.987 5.684-4.747 6.616m-.491-1.38c0 3.856-3.126 6.982-6.982 6.982s-6.982-3.126-6.982-6.982 3.126-6.982 6.982-6.982 6.982 3.126 6.982 6.982z"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<template>
  <div ref="container" class="h-svh w-full">
    <canvas ref="canvas" class="block size-full"></canvas>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from 'vue';

const rectColor = '#1b1b1b';
let interval: number | undefined;

const container = ref<HTMLDivElement | null>(null);
const canvas = ref<HTMLCanvasElement | null>(null);

const getRndInteger = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const start = () => {
  if (!canvas.value || !container.value) return;
  const rect = container.value.getBoundingClientRect();
  canvas.value.width = rect.width;
  canvas.value.height = rect.height;
  const ctx = canvas.value.getContext('2d')!;
  const size = 14;
  const columnWidth = 8;
  const columns = Math.round(canvas.value.width / (columnWidth + size));
  const columnCells = Array(columns)
    .fill(0)
    .map(() => getRndInteger(0, canvas.value!.height));

  const render = () => {
    ctx.fillStyle = 'rgba(0,0,0,.07)';
    ctx.fillRect(0, 0, canvas.value!.width, canvas.value!.height);
    ctx.fillStyle = rectColor;
    columnCells.forEach((y, index) => {
      const x = index * (columnWidth + size);
      ctx.fillRect(x, y, columnWidth, size);
      columnCells[index] =
        y > canvas.value!.height + getRndInteger(0, 10000) ? 0 : y + size;
    });
  };

  interval = window.setInterval(render, 60);
};

const handleResize = () => {
  clearInterval(interval);
  start();
};

onMounted(() => {
  start();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  clearInterval(interval);
  window.removeEventListener('resize', handleResize);
});
</script>
